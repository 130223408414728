html,
body {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  height: 100%;
  width: 100%;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Poppins", sans-serif;
  font-weight: bold !important;
  line-height: 1.4 !important;
}

h1 {
  font-size: 50px !important;
}

a:hover {
  cursor: pointer !important;
}
b {
  font-family: "Poppins", sans-serif;
  font-weight: 700 !important;
}
u {
  border-bottom: 6px solid #e8bf51;
  text-decoration: none;
}
small {
  font-size: 70% !important;
}

.bg-azul {
  background-color: #4454cd !important;
}
.bg-azul-transparente {
  background-color: #4454cde5 !important;
}
.bg-verde {
  background-color: #4cba44 !important;
}
.bg-verde-metade {
  background: linear-gradient(180deg, #4454cd 50%, #5fcc58 50%);
}
.bg-roxo {
  background-color: #834ecc !important;
}
.bg-amarelo {
  background-color: #e8bf51 !important;
}
.bg-cinza {
  background-color: rgb(31, 31, 31);
}
.verde {
  color: #5fcc58;
}
.amarelo {
  color: #e8bf51;
}
.azul {
  color: #4454cd !important;
}
.mt-80 {
  margin-top: 80px;
}
.py-80 {
  padding: 80px 0;
}
.my-0 {
  color: white;
}

/* SEÇÕES */

.hero {
  padding: 70px 0 120px 0;
  background-image: url(./assets/img/bg-verde.jpg);
  background-size: 70%;
  background-position: center bottom;
  background-repeat: no-repeat;
  color: #fff;
  width: 100%;
}
@media (max-width: 572px) {
  .hero {
    background-image: url(./assets/img/lp-hero-bg.svg) !important;
    background-size: 150% !important;
    background-position: left top !important;
  }
}
.bg-semi {
  padding: 50px 0;
  background-image: url(./assets/img/bg-semi-2022-08.jpg);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  width: 100%;
}
@media (max-width: 572px) {
  .bg-semi {
    background-position: bottom;
    background-size: 220%;
  }
  .bg-semi.vh-100 {
    height: 90vh !important;
  }
  .bg-semi h1 {
    font-size: 36px !important;
  }
}
.oferta {
  background: #262626;
  padding: 40px 0;
  border-top: 30px solid #5fcc58;
  color: #fff;
}
.funcionalidades {
  padding-top: 80px;
}
.funcionalidades u {
  border-color: #834ecc;
}
.funcionalidades .btn-azul,
.funcionalidades .btn-azul:hover {
  padding: 15px 40px;
  font-size: 16px;
  margin-top: 0;
  color: #fff;
}
.funcionalidades .lead {
  margin: 20px 0 10px 0;
}
.funcionalidades a,
.funcionalidades a:hover {
  color: #222;
}
.hero.lp {
  background-image: url(./assets/img/lp-hero-bg.svg);
  background-position: left top 20%;
  padding-bottom: 0;
  background-size: 100%;
}
.hero.blackfriday {
  background-image: url(./assets/img/bg-black-friday.jpg) !important;
  background-position: left top 20%;
  padding-bottom: 0;
  background-size: 100%;
}

.hero.lp .lead {
  font-size: 1.2rem;
}
.hero.lp h3 {
  font-size: 2.2rem;
}

.planos {
  height: 100vh;
  display: table;
  width: 100%;
}
.planos-lp {
  height: 350px;
  margin-top: -300px;
}
.outros-planos {
  padding: 50px 0 100px 0;
}
.aulas {
  height: 100vh;
  display: table;
  width: 100%;
  background: #5fcc58;
  color: #ffffff;
  padding: 120px 300px;
}
.aulas u {
  border-color: #834ecc;
}
.depoimentos {
  padding-bottom: 150px;
}
.depoimentos.lp {
  background: url(./assets/img/lp-aprovados.png) no-repeat center;
  padding-top: 80px;
}
.depoimentos .nome {
  padding-left: 10px;
  position: relative;
  vertical-align: middle;
  margin-top: 10px;
}
.depoimentos-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 40px #00000029;
  border: 1px solid #70707076;
  border-radius: 20px;
  padding: 50px 0 40px 0;
  color: #222;
  z-index: 20;
  margin-top: 200px;
  text-align: center;
  position: relative;
  z-index: 20;
}
.carousel {
  margin-top: 30px;
}
.carousel-caption {
  color: #222 !important;
  z-index: 9999;
}
.carousel-item {
  height: 250px;
  width: 80% !important;
  left: 9%;
}
.carousel-indicators [data-bs-target] {
  display: none;
}
.carousel-control-next-icon {
  background-image: url(./assets/img/right-arrow.png) !important;
  opacity: 0.5;
}
.carousel-control-prev-icon {
  background-image: url(./assets/img/left-arrow.png) !important;
  opacity: 0.5;
}
.modal-content {
  background-color: #222 !important;
  color: #fff;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.modal-content iframe {
  max-width: 100%;
}
.modal-content h4 {
  margin: 20px 0;
}
.modal-header {
  color: #fff !important;
  text-align: center !important;
  display: inline !important;
  border-bottom-color: #343434 !important;
  background-color: #4454cd;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.modal-footer {
  display: inline !important;
  text-align: center;
  border-top-color: #343434 !important;
}
.modal-footer button,
.modal-footer button:hover {
  background-color: transparent;
  border: 1px solid #707070;
  font-size: 14px;
}
footer {
  background: #4454cd;
  color: #fff;
  padding: 80px 0;
}
footer ul,
footer ul li {
  list-style: none;
  margin-left: 0;
  padding: 5px 0;
}
footer ul li a,
footer ul li a:hover {
  color: #fff;
  text-decoration: none;
}
footer hr {
  margin-bottom: 40px;
}
footer img {
  margin-left: 10px;
}

/* ELEMENTOS */

.cupom,
.cupom:hover {
  border: 3px dashed #fff;
  padding: 15px 30px;
  font-size: 22px;
  letter-spacing: 10px;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  background: #834ecc;
}
.data {
  font-weight: bold;
  font-size: 38px;
  letter-spacing: 3px;
  background: #5fcc58;
  padding: 20px 30px;
  display: inline-block;
}
.icone {
  background: #4454cd;
  padding: 20px;
  border-radius: 100%;
  width: 110px;
  height: 110px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.btn-borda,
.btn-borda:hover {
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px;
  border: 2px solid #fff;
  color: #fff;
  background-color: none;
  padding: 15px 40px;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  display: inline-block;
  z-index: 9999;
}
.btn-login,
.btn-login:hover {
  font-size: 16px;
  border-radius: 10px;
  background-color: #0ba428;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  margin-left: 20px;
}
.btn-verde,
.btn-verde:hover {
  background-color: #0ba428;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  padding: 20px 30px;
  text-decoration: none;
  border-radius: 10px;
  display: inline-block;
}

.btn-assinar,
.btn-assinar:hover {
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px;
  background-color: #4454cd;
  padding: 15px 50px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  color: #fff !important;
}

.btn-planos,
.btn-planos:hover {
  font-weight: bold;
  font-size: 16px;
  border-radius: 80px;
  background-color: #4454cd;
  padding: 20px 50px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}

.btn-azul,
.btn-azul:hover {
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  background-color: #4454cd;
  padding: 20px 60px;
  color: #fff;
  text-decoration: none;
  margin-top: 40px;
  display: inline-block;
}

.lead {
  display: block;
  margin: 20px 0;
}

.card {
  z-index: 10;
  border-radius: 15px !important;
  border: #707070 !important;
  box-shadow: 5px 5px 40px #00000029;
  position: relative;
  z-index: 100;
}
.card.lp {
  margin-top: 0 !important;
  text-align: center;
}
.card-header {
  color: #fff;
  padding: 20px 0 !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.card-header h4 {
  font-size: 22px !important;
}
.card-header img {
  margin-top: -4px;
}
.card .valor {
  padding: 25px 0 15px 0;
  color: #343434;
  background-color: #f2f2f2 !important;
}
.card .valor h3 {
  margin: 5px 0 !important;
  font-size: 30px;
}
.card .list-unstyled li {
  text-align: left;
  padding-left: 35px;
  margin: 10px 0 15px 30px;
  color: #343434;
}
.card .list-unstyled.check li {
  background: url(./assets/img/check.png) top left no-repeat;
}
.card .list-unstyled.uncheck {
  margin-top: -15px !important;
}
.card .list-unstyled.uncheck li {
  background: url(./assets/img/close.png) left no-repeat;
  color: #262626a7;
}
.card .list-unstyled li a {
  color: #5fcc58;
  font-weight: bold;
}
.card .list-unstyled li Button {
  background: none;
  padding: 0;
  border: 0;
  color: inherit;
  text-decoration: underline;
}
.box,
.box:hover {
  color: #fff;
  border-radius: 20px;
  padding: 50px 40px 30px 40px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  margin-top: 50px;
  max-width: 450px;
  box-shadow: 5px 5px 40px #00000029;
}
.whatsapp_float,
.whatsapp_float:hover {
  position: fixed;
  width: 60px;
  height: 60px;
  padding-top: 3px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}
.whatsapp_float a {
  color: #fff !important;
}

/* LPs */

#lp-prof .hero.lp {
  padding-bottom: 0;
}
#lp-prof .hero h1 {
  line-height: 140%;
  margin: 10px 0;
}
#lp-prof .hero.lp img.img-fluid {
  margin: 0;
}
#lp-prof .hero.lp .lead {
  padding: 0 20px;
  font-size: 16px;
}
@media (min-width: 680px) {
  #lp-prof .hero.lp {
    max-height: 650px;
    overflow: hidden;
    padding-top: 50px;
  }
  #lp-prof .hero.lp .lead {
    padding: 0;
    font-size: 26px;
    width: 500px;
  }
}
.btn.super-enem {
  font-weight: bold;
  font-size: 16px;
  border-radius: 80px;
  background-color: #834ecc;
  border: 2px solid #ffffff;
  padding: 20px 0;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  margin: 10px;
  color: #fff !important;
  width: 150px;
  text-align: center;
}
.btn.super-med {
  font-weight: bold;
  font-size: 16px;
  border-radius: 80px;
  background-color: #4454cd;
  border: 2px solid #ffffff;
  padding: 20px 0;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  margin: 10px;
  color: #fff !important;
  width: 150px;
  text-align: center;
}

/* MOBILE */

@media (max-width: 991px) {
  .hero {
    height: auto;
    display: inherit;
    padding-top: 30px;
    padding-bottom: 50px;
    background-size: 70%;
    background-position: center bottom;
    text-align: center;
  }
  .oferta {
    text-align: center;
  }
  .funcionalidades {
    text-align: center;
  }
  .funcionalidades img {
    margin: 30px 0;
  }
  .hero a {
    margin-bottom: 20px;
  }
  .hero.lp {
    background-position: left top 5%;
    padding-bottom: 30px;
  }
  .hero h1 {
    font-size: 28px !important;
  }
  .hero.lp h1 {
    font-size: 30px !important;
  }
  .hero.lp .lead {
    padding: 0 20px;
    font-size: 18px;
    margin: 15px 0;
  }
  .hero.lp img.img-fluid {
    margin-bottom: 50px;
  }
  .planos-lp {
    height: 350px;
    margin-top: -300px;
  }
  .carousel-caption {
    left: 0 !important;
    right: 0 !important;
    font-size: 16px !important;
  }
  .card {
    margin-top: 0 !important;
    margin-bottom: 20px;
  }
  .card ul li {
    margin-left: 10px !important;
  }
  .box {
    margin-top: 20px;
  }
  #planos {
    margin-top: 50px;
  }
  .navbar-light .navbar-toggler {
    background: #5fcc58;
  }
  .navbar-light .navbar-nav .nav-link {
    border-bottom: 1px solid #7380dc;
    padding: 15px 0;
    text-align: center;
  }
  .btn-experimente,
  .btn-experimente:hover,
  .btn-login,
  .btn-login:hover {
    display: block;
    margin: 20px auto;
    width: 250px;
    text-align: center;
  }
  .basic-navbar-nav {
    border: 1px solid #7380dc;
    margin-bottom: 30px;
  }
  .carousel-item {
    height: 310px;
  }
  .depoimentos {
    padding-bottom: 50px;
  }
  .depoimentos-box {
    padding-bottom: 0;
    width: 100%;
  }
  .aulas {
    height: auto;
    display: inherit;
    padding: 50px 20px;
  }
  .depoimentos img.aprovados {
    margin-top: 0px;
  }
  footer img {
    margin: 10px;
  }
  footer .d-flex {
    text-align: center;
    display: inherit !important;
  }
  .whatsapp_float,
  .whatsapp_float:hover {
    bottom: 20px;
    right: 20px;
  }
}

/* BOOTSTRAP */

.navbar {
  padding: 0 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
  font-size: 16px;
  padding-left: 25px !important;
  font-family: "Poppins", sans-serif !important;
}
.dropdown-item {
  color: #fff !important;
  padding: 10px 25px !important;
}
.dropdown-item:hover {
  background: #0ba428 !important;
  color: #fff;
}

/* COUNTDOWN */

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  margin: 20px auto 0;
  border: 3px solid #5fcc58;
  width: 300px;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

/* Mega Help */

.megahelp {
  background: url(./assets/img/bg-megahelp.jpg) no-repeat;
  background-size: cover;
  background-position: top center;
}

/* 2023 */

#header {
  background: url(./assets/img/bg-home.webp) no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0 0 30px 20px;
}
#header.pv {
  background: url(./assets/img/bg-pre-vestibular.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  padding: 100px 20px;
}
#header.med {
  background: url(./assets/img/bg-med.jpg) no-repeat;
  background-size: cover;
  background-position: 40%;
  padding: 100px 20px;
}
#header h1 {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  line-height: 58px !important;
  padding-top: 280px;
}
#header h1 span {
  color: #05e41b;
}
#header p {
  font-size: 20px;
  color: #fff;
  padding: 20px 0;
}
#header a.btn {
  background-color: #0ba428;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  padding: 20px 30px;
}
#metodo {
  background: url(./assets/img/bg-analise.jpg) no-repeat;
  background-size: cover;
  background-position: center right;
  padding: 100px 0;
  color: #fff;
}
#metodo h2 {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
}
#metodo span {
  color: #2fbff9;
}
#metodo p {
  font-size: 18px;
}
#metodo a.btn {
  border: 3px solid #2fbff9;
  color: #fff;
  padding: 10px 60px;
}
#beneficios {
  padding: 80px 0 100px 0;
}
#beneficios h1 {
  font-weight: bold;
  font-size: 32px !important;
  color: #4454cd;
}
#beneficios h1 span {
  color: #00cd4d;
}
#beneficios h2 {
  color: #4b4b4b;
  font-size: 22px;
}
#beneficios .box {
  padding: 0;
  max-width: 100%;
}
#beneficios .box img {
  float: left;
  margin-right: 15px;
  width: 149px;
}
#beneficios .box p {
  color: #000;
  font-size: 16px;
  padding: 20px;
  text-align: left;
  margin-bottom: 0;
}
#amostra {
  padding: 50px 0;
}
#amostra h1 {
  font-size: 36px !important;
  font-weight: bold;
  line-height: 52px !important;
  margin-bottom: 20px;
}
#amostra h1 span {
  color: #00cd4d;
}
#amostra p {
  font-size: 18px;
}
#amostra a.btn {
  background-color: #00cd4d;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  padding: 20px 30px;
}
.top-spacing {
  height: 100px;
  width: 100%;
  display: block;
}

@media (max-width: 414px) {
  #header {
    padding: 20px;
    background-position: 30%;
  }
  #header h1 {
    font-size: 28px !important;
    line-height: 1.2 !important;
    padding-top: 180px;
  }
  #header.pv {
    background-position: 30%;
  }
  #header.pv h1 {
    padding-top: 80px;
  }
  #header.med {
    background-position: top;
    background-size: 330%;
    background-color: #4454cd;
  }
  #header.med h1 {
    padding-top: 80px;
  }
  #header p {
    padding: 10px 0;
  }
  #metodo {
    padding: 40px 20px;
    background-position: 40%;
  }
  #beneficios {
    padding: 40px 20px;
  }
  #beneficios h1 {
    font-size: 28px !important;
  }
  #beneficios .box img {
    width: 80px;
    margin-right: 10px;
  }
  #beneficios .box p {
    padding: 10px;
  }
  #amostra {
    padding: 40px 20px;
  }
  #amostra h1 {
    font-size: 28px !important;
    line-height: 1.2 !important;
  }
  #planos {
    padding: 0;
  }
  .top-spacing {
    height: 140px;
  }
}

/* FONTES */

@font-face {
  font-family: "Futura";
  src: url("./assets/fonts/Futura.ttc") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Futura-Bold";
  src: url("./assets/fonts/Futura-Bold.ttf") format("truetype");
  font-weight: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
